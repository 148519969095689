import { styled } from '@mui/material'
import { HTMLAttributes, MouseEventHandler } from 'react'

import { CapStatus } from '@nuna/api'
import { TextButton, body2 } from '@nuna/tunic'

import { CapProgressBar } from '../CapProgressBar'
import { useCapProgressQuery } from './useCapProgressQuery'

export interface CapProgressProps extends HTMLAttributes<HTMLDivElement> {
  patientId?: string
  onLearnMoreClick?: MouseEventHandler<HTMLButtonElement>
}

export function CapProgress({ patientId, onLearnMoreClick, ...props }: CapProgressProps) {
  const { data, loading } = useCapProgressQuery(patientId)

  if (loading || !data)
    return (
      <Container style={{ width: 292 }} className={`loading ${props.className}`}>
        Loading
      </Container>
    )

  const sessionLimit = data.appointmentConfiguration.capInfo.sessionNumberLimit ?? 0
  const attended = data.appointmentConfiguration.capInfo.sessionsAttended
  const attendedCapStatus = data.appointmentConfiguration.capInfo.attendedCapStatus
  const futureAppointmentsInCap = data.appointmentConfiguration.capInfo.futureAppointmentsInCap ?? 0
  const sessionsCountingTowardsCap = data.appointmentConfiguration.capInfo.sessionsCountingTowardsCap ?? 0
  const hasPaymentMethod = data.appointmentConfiguration.patientHasPaymentMethod

  const reachedCashPay = attendedCapStatus === CapStatus.Exhausted && hasPaymentMethod

  let capText

  if (reachedCashPay) {
    capText = `You've used all your free sessions / Continue care using insurance or cash`
  } else if (attended === 0 && sessionsCountingTowardsCap === 0) {
    capText = `You have ${sessionLimit} covered sessions`
  } else if (attended === 0 && sessionsCountingTowardsCap > 0) {
    capText = `${futureAppointmentsInCap} Upcoming • ${sessionLimit} Covered`
  } else {
    capText = `${attended} Attended • ${futureAppointmentsInCap} Upcoming • ${sessionLimit} Covered`
  }

  let learnMoreText

  if (attended === 0 && sessionsCountingTowardsCap === 0) {
    learnMoreText = 'Learn about session limits'
  } else {
    learnMoreText = 'Learn more'
  }

  return (
    <Container data-testid="cap-progress" {...props}>
      {!reachedCashPay && (attended > 0 || sessionsCountingTowardsCap > 0) && (
        <CapProgressBar
          className="mr-1"
          capInfo={data.appointmentConfiguration.capInfo}
          hasPaymentMethod={hasPaymentMethod}
        />
      )}

      <CapText>{capText}</CapText>
      {onLearnMoreClick && <TextButton onClick={onLearnMoreClick}>{learnMoreText}</TextButton>}
    </Container>
  )
}

const Container = styled('div')`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--margin-1));

  > * {
    margin-top: var(--margin-1);
  }
`

const CapText = styled('span')`
  color: ${body2};
  margin-right: var(--margin-1);
`
