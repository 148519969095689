import { styled } from '@mui/material'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  SelectProps as MUISelectProps,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { ReactNode, useEffect, useRef, useState } from 'react'

import {
  AddressEnrollmentStatus,
  BasicPatientFragment,
  ProviderTherapyLocationsQuery,
  PublicProviderQuery,
  Role,
  useProviderTherapyLocationsQuery,
  usePublicProviderQuery,
} from '@nuna/api'
import { useAuthDataContext, useHasRole, useIsAdmin } from '@nuna/auth'
import { addressService, routeService } from '@nuna/core'
import {
  Divider,
  Grid,
  IconClock,
  IconMapMarker,
  MenuItem as NunaMenuItem,
  SpinningLoader,
  StatusLabel,
  Tooltip,
  bodyPrimary,
  bodySecondary,
  interactiveFill,
  styledUtils,
} from '@nuna/tunic'

const { adminProviderPreferencesLocations, providerPreferencesLocations } = routeService
const { transientPropOptions } = styledUtils

interface AppointmentLocationSelectProps extends Omit<MUISelectProps, 'onChange' | 'variant' | 'defaultValue'> {
  providerId: string
  containerProps?: React.HTMLProps<HTMLDivElement>
  error?: boolean
  helperText?: string | ReactNode
  label?: string
  name?: string
  value?: string | null
  showMapMarker?: boolean
  onAddressChange: (selectedAddress: AppointmentLocationSelectValue) => void
  patient: Pick<BasicPatientFragment, 'id'> | undefined
  removeVirtualOption?: boolean
}

export interface AppointmentLocationSelectValue {
  id: string
  name: string
}

type ProviderTherapyLocation = ProviderTherapyLocationsQuery['providerTherapyLocations'][number]
type PublicProviderTherapyLocation = PublicProviderQuery['publicProvider']['therapyLocations'][number]

type SpreadAddress<T extends { address?: ProviderTherapyLocation['address'] }> = Omit<T, 'address'> & T['address']
type AdjustedProviderTherapyLocation = SpreadAddress<ProviderTherapyLocation>
type MergedLocation = Omit<Partial<AdjustedProviderTherapyLocation>, '__typename'> &
  Omit<PublicProviderTherapyLocation, '__typename'>

export function AppointmentLocationSelect({
  providerId,
  helperText,
  containerProps = {},
  error = false,
  label = 'Location',
  value: locationValue,
  showMapMarker = true,
  onAddressChange,
  patient,
  removeVirtualOption = false,
  ...props
}: AppointmentLocationSelectProps) {
  const isAdmin = useIsAdmin()
  const isPatient = useHasRole(Role.Patient)
  const { loggedIn } = useAuthDataContext()
  const [isFocused, setIsFocused] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined)
  const addLocationLink =
    isPatient || !loggedIn
      ? null
      : isAdmin
      ? adminProviderPreferencesLocations(providerId)
      : providerPreferencesLocations()

  useEffect(() => {
    if (isOpen && selectRef.current) {
      const selectWidth = selectRef.current.getBoundingClientRect().width
      setMenuWidth(selectWidth)
    }
  }, [isOpen])

  const {
    data,
    loading: isLoadingLocations,
    error: loadingAddressesError,
  } = useProviderTherapyLocationsQuery({
    variables: { providerId, patientId: patient ? patient.id : '' },
    skip: !providerId || !patient,
  })

  const { data: publicProviderData } = usePublicProviderQuery({
    variables: { providerId, patientId: patient ? patient.id : undefined },
    skip: !providerId,
  })
  const {
    publicProvider: { therapyLocations },
  } = publicProviderData ?? { publicProvider: { therapyLocations: [] } }

  const mergedLocations: MergedLocation[] = [
    ...((data?.providerTherapyLocations.map(location => ({
      ...location,
      ...location.address,
      address: undefined,
      __typename: undefined,
    })) as AdjustedProviderTherapyLocation[]) ?? []),
    ...(therapyLocations as PublicProviderTherapyLocation[]),
  ]

  // Use a map to merge locations with the same id
  const locationMap = new Map<string, MergedLocation>()
  mergedLocations.forEach(location => {
    if (locationMap.has(location.id)) {
      const existingLocation = locationMap.get(location.id)
      if (existingLocation) {
        locationMap.set(location.id, { ...existingLocation, ...location })
      } else {
        locationMap.set(location.id, location)
      }
    } else {
      locationMap.set(location.id, location)
    }
  })

  const locations = Array.from(locationMap.values())

  const { className = '', ...containerRest } = containerProps

  const getSelectedAddress = (addressId?: string | null) => locations.find(location => location.id === addressId)

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const value = (event.target.value || '') as string
    const selectedObject = getSelectedAddress(value)
    if (selectedObject) {
      onAddressChange({ id: selectedObject.id, name: selectedObject.name })
    } else if (value === 'Virtual') {
      onAddressChange({ id: 'Virtual', name: 'Virtual' })
    }
  }

  const initialLocationValue = removeVirtualOption
    ? getSelectedAddress(locationValue)?.id ?? ''
    : getSelectedAddress(locationValue)?.id ?? 'Virtual'

  return (
    <>
      {/* I apologize for this, but there was no other way 🙏 */}
      <style>
        {`
          .MuiPopover-root .MuiMenu-list {
            padding-top: 0;
            padding-bottom: 0;
          }
        `}
      </style>
      <div className={`v-align ${className}`} {...containerRest}>
        {showMapMarker && (
          <IconMapMarker color={isFocused || isOpen ? interactiveFill : bodySecondary} className={`mr-1`} />
        )}
        <FormControlStyled fullWidth $isFocused={isFocused || isOpen} error={error} ref={selectRef}>
          <InputLabel>{label}</InputLabel>
          <Select
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            value={initialLocationValue}
            onChange={handleSelectChange}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderValue={(locationValue: any) =>
              isLoadingLocations ? (
                <div>Loading locations...</div>
              ) : !loadingAddressesError ? (
                <div>
                  {locationValue.toLowerCase() !== 'virtual' ? getSelectedAddress(locationValue)?.name : locationValue}
                </div>
              ) : (
                <div>Error loading locations</div>
              )
            }
            MenuProps={{
              style: {
                maxHeight: 350,
              },
              PaperProps: {
                style: {
                  width: menuWidth,
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            disabled={isLoadingLocations}
            {...props}
          >
            {!removeVirtualOption && (
              <MenuItemStyled key="virtual" value="Virtual">
                <Grid container>
                  <Grid
                    size={{
                      xs: 12,
                    }}
                  >
                    Virtual
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                    }}
                  >
                    <span className="caption text-light-grey">Tava Video Call</span>
                  </Grid>
                </Grid>
              </MenuItemStyled>
            )}
            {isLoadingLocations && (
              <MenuItem value="loading">
                <SpinningLoader />
                Loading locations
              </MenuItem>
            )}
            {locations.map(location => {
              const { id, name } = location
              const hasInsuranceInfo = 'isInsuranceCoveragePrimary' in location && 'enrollmentStatus' in location
              // ignore insurance enrollment status if it's not available.
              // These aren't available on the unauthenticated endpoint due to not knowing patient's insurance.
              // BE will only provide valid locations for clients and we don't want to show the UI label to them.
              const isPendingEnrollment = hasInsuranceInfo
                ? location.isInsuranceCoveragePrimary && location.enrollmentStatus !== AddressEnrollmentStatus.Enrolled
                : false

              return (
                <MenuItemStyled key={id} value={id} disabled={isPendingEnrollment}>
                  <Grid container>
                    <Grid size={{ xs: 12 }} className="v-align">
                      <span className="v-align full-width">
                        {name}
                        {isPendingEnrollment && (
                          <span className="ml-auto">
                            <Tooltip content="Not available for this client's insurance yet. We'll notify you when your location is approved by insurance payers.">
                              <StatusLabel className="v-align pending-enrollment-label">
                                <IconClock size={16} className="mr-xs" /> Pending
                              </StatusLabel>
                            </Tooltip>
                          </span>
                        )}
                      </span>
                    </Grid>
                    <Grid size={{ xs: 12 }} style={{ lineHeight: 1.4 }}>
                      <span className="caption text-light-grey">{addressService.formatAddress(location)}</span>
                    </Grid>
                  </Grid>
                </MenuItemStyled>
              )
            })}
            {addLocationLink && [
              <Divider key="add-location-divider" />,
              <AddNewItem key={addLocationLink} to={addLocationLink} relative={'path'} target="_blank">
                <span>Add New Location</span>
              </AddNewItem>,
            ]}
          </Select>

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControlStyled>
      </div>
    </>
  )
}

const FormControlStyled = styled(FormControl, transientPropOptions)<{ $isFocused: boolean }>`
  ${({ $isFocused }) =>
    $isFocused &&
    `
  .MuiInput-underline {
    &::after {
      border-bottom-color: ${interactiveFill};
      transform: scaleX(1);
    }
  }
`}
`
const AddNewItem = styled(NunaMenuItem)`
  && {
    margin-top: 0;
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
    border-radius: 0;
  }
`

const MenuItemStyled = styled(MenuItem)<{ sameAsAppointment?: boolean; disabled?: boolean }>`
  && {
    color: ${bodyPrimary};
    font-weight: 500;
    white-space: normal;

    ${props =>
      (props.sameAsAppointment || props.disabled) &&
      `
    font-weight: 400;
  `}

    ${props =>
      props.sameAsAppointment &&
      `
        background: none !important;
        padding: 1px 8px;
  `}
  }

  .pending-enrollment-label {
    pointer-events: auto;
  }
`
