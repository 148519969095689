import { useEffect } from 'react'

import { NoteGenerationStatus, useSessionSummaryQuery } from '@nuna/api'

const POLL_INTERVAL = 10000

export function useSessionSummaryPoll(appointmentId: string, { skip = false } = {}) {
  const queryResult = useSessionSummaryQuery({
    variables: { appointmentId },
    pollInterval: POLL_INTERVAL,
    skip,
    fetchPolicy: 'network-only',
  })

  const { error, stopPolling, data } = queryResult
  const sessionSummary = data?.sessionSummary

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.warn(`Session summary was not found: ${error}`)
    }
  }, [error])

  useEffect(() => {
    if (!sessionSummary) return

    if (
      sessionSummary.noteGenerationStatus === NoteGenerationStatus.Completed ||
      sessionSummary.noteGenerationStatus === NoteGenerationStatus.Failed
    ) {
      stopPolling()
    }
  }, [sessionSummary, stopPolling])

  return queryResult
}
