import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import { isNil } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useRef } from 'react'

import {
  AppointmentStatsDocument,
  Diagnosis,
  DurationPlatformType,
  PaymentPreference,
  usePatientSessionNotesQuery,
  useSaveSessionMutation,
} from '@nuna/api'
import { AppointmentLocationSelect, AppointmentLocationSelectValue, TherapyTypeSelect } from '@nuna/appointment'
import { useTimeZoneContext } from '@nuna/common'
import { errorService, formService, specialtiesService, timeService } from '@nuna/core'
import { sessionCaptureService, supportService } from '@nuna/telemetry'
import {
  ContextualAlert,
  FillButton,
  GhostButton,
  GhostButtonExternalLink,
  Grid,
  IconInfo,
  TextField,
  toast,
  validateTime,
} from '@nuna/tunic'

import { useSessionDraftConflictWarning } from '../../../hooks/useSessionDraftConflictWarning'
import { SessionData } from '../../../types'
import { getBilledDuration, hasInSessionNotes, mergeAppointmentDateTime } from '../../../utils'
import { SessionNoteHistory } from '../../SessionNoteHistory'
import { AutonoteFeedback } from './AutonoteFeedback'
import { AutonoteStatus } from './AutonoteStatus'
import { CompensationEstimate } from './CompensationEstimate'
import { DiagnosesSelect } from './DiagnosesSelect'
import { IncompleteIntakeAlert } from './IncompleteIntakeAlert'
import { InputHeading } from './InputHeading'
import { NoteField } from './NoteField'
import { SkippedNoteAlert } from './SkippedNoteAlert'

const { composeHelperTextWithError } = formService

export interface SessionNoteFormValues {
  addressId: string
  diagnoses: Diagnosis[]
  endTime: string
  startTime: string
  therapyTypeSpecialtyId: string
  workingDraft: string | null
}

export const SESSION_NOTE_CHAR_LIMIT = 70000
const SESSION_NOTE_CHAR_MIN = 250

interface Props {
  onPublished: (sessionId: string) => void
  onCancelEdit: () => void
  sessionData: SessionData
  isEditing: boolean
  setIsEditing: (value: boolean) => void
}

export function SessionNoteForm({ sessionData, onCancelEdit, onPublished, isEditing, setIsEditing }: Props) {
  useSessionDraftConflictWarning(sessionData.appointmentId)
  const [saveSessionMutation, { loading }] = useSaveSessionMutation({
    refetchQueries: [AppointmentStatsDocument],
  })
  const { addUserInContext, removeUserInContext, timeZoneChange } = useTimeZoneContext()
  const formRef = useRef<FormikProps<SessionNoteFormValues> | null>(null)
  const { data: pastSessionNotesData, error: pastSessionNotesError } = usePatientSessionNotesQuery({
    variables: { id: sessionData.patient.id },
  })
  // Default the available therapy types to whatever the provider accepts in the rare event that we can't determine
  // the true options from coverage-- right now this only happens when a provider is documenting a note for a patient
  // who is no longer part of their caseload. But Smart Everything should solve everything.
  const defaultTherapyTypes = sessionData.provider.specialties
    .filter(specialty => specialtiesService.isTherapyType(specialty.name))
    .map(({ __typename, ...specialty }) => ({ ...specialty, enabled: true }))

  useEffect(() => {
    if (pastSessionNotesError) {
      toast.urgent(errorService.transformGraphQlError(pastSessionNotesError, 'There was a problem loading past notes'))
    }
  }, [pastSessionNotesError])

  const pastSessionNotes = pastSessionNotesData?.patient.sessions ?? []

  useEffect(() => {
    const handler = (newTimeZone?: string | null, oldTimeZone?: string | null) => {
      if (newTimeZone && oldTimeZone && formRef.current?.values.startTime && formRef.current?.values.endTime) {
        formRef.current?.setFieldValue(
          'startTime',
          timeService.adjustTimeForTimeZoneChange({
            newTimeZone,
            oldTimeZone,
            time: formRef.current?.values.startTime,
            format: 'h:mm A',
          }),
        )
        formRef.current?.setFieldValue(
          'endTime',
          timeService.adjustTimeForTimeZoneChange({
            newTimeZone,
            oldTimeZone,
            time: formRef.current?.values.endTime,
            format: 'h:mm A',
          }),
        )
      }
    }
    timeZoneChange('on', handler)
    addUserInContext(sessionData.provider)
    addUserInContext(sessionData.patient)
    return () => {
      removeUserInContext('Provider')
      removeUserInContext('Patient')
      timeZoneChange('off', handler)
    }
  }, [addUserInContext, removeUserInContext, timeZoneChange, sessionData.patient, sessionData.provider])

  useEffect(() => {
    sessionCaptureService.setCustomFields({ tavaScribeEnabled: sessionData.provider.tavaScribeEnabled })
  }, [sessionData.provider.tavaScribeEnabled])

  const appointmentDate = useMemo(() => moment(sessionData.scheduledStartTime), [sessionData.scheduledStartTime])

  const handleSubmit = async (values: SessionNoteFormValues) => {
    const billedDuration = getBilledDuration({
      appointmentDate,
      startTime: values.startTime,
      endTime: values.endTime,
    })

    try {
      const response = await saveSessionMutation({
        variables: {
          id: sessionData.sessionId,
          patientId: sessionData.patient.id,
          startTime: mergeAppointmentDateTime(sessionData.scheduledStartTime, values.startTime, true),
          endTime: mergeAppointmentDateTime(sessionData.scheduledEndTime, values.endTime, true),
          date: appointmentDate,
          locked: false,
          note: values.workingDraft ?? '',
          plan: '', // deprecated but still required by mutation
          appointmentId: sessionData.appointmentId,
          diagnoses: values.diagnoses.map(diagnosis => diagnosis.id),
          billedDuration,
          therapyTypeSpecialtyId: values.therapyTypeSpecialtyId,
          durationPlatformType: DurationPlatformType.AllOnTavahealthPlatform, // Hard-coded for now until we add variation to the form
          addressId: values.addressId.toLowerCase() === 'virtual' ? undefined : values.addressId,
        },
      })

      const savedSessionId = response.data?.saveSession.id

      if (!savedSessionId) {
        throw new Error('Response data is missing')
      }

      setTimeout(() => {
        setIsEditing(false)
        onPublished(savedSessionId)
      }, 250)
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformGraphQlError(e, 'There was a problem saving the note. Please try again.'))
    }
  }

  return (
    <Formik
      initialValues={buildInitialValues(sessionData)}
      onSubmit={handleSubmit}
      validationSchema={buildSchema({
        sessionData,
      })}
      innerRef={ref => (formRef.current = ref)}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        getFieldProps,
        resetForm,
      }) => {
        const billedDuration = getBilledDuration({
          appointmentDate,
          startTime: values.startTime,
          endTime: values.endTime,
        })

        const sharedProps = {
          disabled: !isEditing || sessionData.billableFieldsDisabled,
        }

        return (
          <Form className="mt-3" onSubmit={handleSubmit}>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                  lg: 4,
                }}
              >
                <InputHeading>Type</InputHeading>

                <TherapyTypeSelect
                  {...sharedProps}
                  {...getFieldProps('therapyTypeSpecialtyId')}
                  onChange={therapyTypeId => setFieldValue('therapyTypeSpecialtyId', therapyTypeId)}
                  patientId={sessionData.patient.id}
                  providerId={sessionData.provider.id}
                  showAllTypes={!!sessionData.sessionId}
                  defaultTherapyTypes={defaultTherapyTypes}
                  {...composeHelperTextWithError('', errors.therapyTypeSpecialtyId, touched.therapyTypeSpecialtyId)}
                />
              </Grid>

              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                  lg: 4,
                }}
              >
                <InputHeading>Location</InputHeading>

                <AppointmentLocationSelect
                  {...sharedProps}
                  {...getFieldProps('addressId')}
                  providerId={sessionData.provider.id}
                  onAddressChange={(selectedAddress: AppointmentLocationSelectValue) =>
                    setFieldValue('addressId', selectedAddress.id)
                  }
                  label=""
                  showMapMarker={false}
                  patient={sessionData.patient}
                  {...composeHelperTextWithError('', errors.addressId, touched.addressId)}
                />
              </Grid>

              <Grid
                size={{
                  xs: 6,
                  lg: 2,
                }}
              >
                <InputHeading>Duration</InputHeading>
                <StyledTextField
                  {...sharedProps}
                  {...getFieldProps('startTime')}
                  type="time"
                  placeholder={moment(sessionData.scheduledStartTime).format('h:mm A')}
                  {...composeHelperTextWithError('Start time', errors.startTime, touched.startTime)}
                />

                {values.startTime && values.endTime && !errors.startTime && !errors.endTime && (
                  <div className="pt-1">
                    <CompensationEstimate
                      className="no-wrap"
                      billedDuration={billedDuration}
                      providerId={sessionData.provider.id}
                      appointmentId={sessionData.appointmentId}
                      style={{ flex: '0 0 auto' }}
                    />
                  </div>
                )}
              </Grid>

              <Grid
                size={{
                  xs: 6,
                  lg: 2,
                }}
              >
                <InputHeading>&nbsp;</InputHeading>
                <StyledTextField
                  {...sharedProps}
                  {...getFieldProps('endTime')}
                  type="time"
                  placeholder={moment(sessionData.scheduledStartTime).add(53, 'minutes').format('h:mm A')}
                  urgent={!values.endTime}
                  {...composeHelperTextWithError(
                    !values.endTime ? 'Please enter end time' : 'End time',
                    errors.endTime,
                    touched.endTime,
                  )}
                />
              </Grid>

              <Grid size={12}>
                <div className="mb-1 v-align">
                  <InputHeading>Diagnoses</InputHeading>

                  <GhostButtonExternalLink
                    className="ml-1 caption"
                    variant="secondary"
                    href={supportService.articles.diagnoses}
                  >
                    Learn More
                  </GhostButtonExternalLink>
                </div>

                <DiagnosesSelect
                  {...sharedProps}
                  {...getFieldProps('diagnoses')}
                  max={12}
                  coverageType={sessionData.coverageType}
                  className="full-width mb-2"
                  onChange={(_e, diagnoses) => {
                    setFieldValue('diagnoses', diagnoses)
                    setFieldTouched('diagnoses', true)
                  }}
                  {...composeHelperTextWithError('', errors.diagnoses, !!touched.diagnoses)}
                  onRemove={diagnosisId => {
                    setFieldTouched('diagnoses', true)
                    setFieldValue(
                      'diagnoses',
                      values.diagnoses.filter(d => d.id !== diagnosisId),
                    )
                  }}
                />
              </Grid>

              {sessionData.isFirstSession && (
                <Grid
                  size={{
                    xs: 12,
                    lg: 8,
                  }}
                >
                  <ContextualAlert intent="information" icon={<IconInfo />}>
                    Since this is your first session with {sessionData.patient.firstName}, your note should include a
                    biopsychosocial assessment and treatment plan (mental status exam, challenges, treatment goals). We
                    provided you with an outline below.
                  </ContextualAlert>
                </Grid>
              )}

              {sessionData.billableFieldsDisabled && isEditing && (
                <Grid size={12}>
                  <ContextualAlert intent="caution">
                    Because this session has already been billed, you can only edit the session note
                  </ContextualAlert>
                </Grid>
              )}

              {sessionData.sessionSummary?.noteGenerationSkippedReason && sessionData.provider.tavaScribeEnabled && (
                <Grid size={12}>
                  <SkippedNoteAlert
                    patient={sessionData.patient}
                    reason={sessionData.sessionSummary?.noteGenerationSkippedReason}
                  />
                </Grid>
              )}

              <Grid size={12}>
                <AutonoteStatus providerId={sessionData.provider.id} />
              </Grid>
            </Grid>
            <Grid sx={{ mt: 1 }} container spacing={3} direction="row-reverse">
              {(() => {
                const showSidebar = hasInSessionNotes(sessionData) || pastSessionNotes.length > 0

                return (
                  <>
                    {showSidebar && (
                      <Grid
                        size={{
                          xs: 12,
                          md: 6,
                          lg: 4,
                        }}
                      >
                        <SessionNoteHistory
                          pastSessionNotes={pastSessionNotes}
                          noteDraft={sessionData.inSessionNotes}
                        />
                      </Grid>
                    )}
                    <Grid size={{ xs: 12, ...(showSidebar ? { md: 6, lg: 8 } : {}) }}>
                      <NoteField
                        disabled={!isEditing}
                        sessionData={sessionData}
                        renderCardFooter={
                          isEditing &&
                          !isNil(values.workingDraft) && (
                            <div className="mt-2 py-1 v-align">
                              <FillButton
                                isLoading={loading}
                                disabled={
                                  isNil(values.workingDraft) ||
                                  loading ||
                                  isSubmitting ||
                                  !sessionData.patient.intakeCompleted
                                }
                                type="submit"
                              >
                                Submit
                              </FillButton>

                              {sessionData.sessionId && (
                                <GhostButton
                                  onClick={() => {
                                    resetForm()
                                    onCancelEdit()
                                  }}
                                  className="ml-2"
                                  disabled={loading || isSubmitting}
                                >
                                  Cancel
                                </GhostButton>
                              )}

                              <AutonoteFeedback className="ml-auto" sessionData={sessionData} />
                            </div>
                          )
                        }
                      />
                      {!sessionData.patient.intakeCompleted && <IncompleteIntakeAlert sessionData={sessionData} />}
                    </Grid>
                  </>
                )
              })()}
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

const Form = styled('form')`
  max-width: 1350px;
`

const StyledTextField = styled(TextField)`
  & ::placeholder {
    color: currentColor;
    opacity: 0.42; // matches diagnoses placeholder
  }
`

function buildSchema({ sessionData }: { sessionData: SessionData }) {
  let diagnosesValidation = Yup.array<Diagnosis>().ensure().max(12, 'A maximum of 12 diagnoses is allowed.')
  if (sessionData.coverageType === PaymentPreference.Insurance) {
    diagnosesValidation = diagnosesValidation.test(
      'primary-diagnosis',
      'Please add at least one medically necessary diagnosis.',
      (value?: Diagnosis[] | null) =>
        (value ?? []).filter(diagnosis => diagnosis.diagnosisLevel === 'PRIMARY').length >= 1,
    )
  } else {
    diagnosesValidation = diagnosesValidation.ensure().min(1, 'At least one diagnosis is required.')
  }

  return Yup.object().shape<SessionNoteFormValues>({
    workingDraft: Yup.string()
      .required('Note must be at least 250 characters')
      .min(SESSION_NOTE_CHAR_MIN, `Note must be at least ${SESSION_NOTE_CHAR_MIN} characters`)
      .max(SESSION_NOTE_CHAR_LIMIT, 'Note too long')
      .test('is-different', 'Please complete the session note', value => {
        if (!sessionData.template) {
          return true
        }
        return value !== sessionData.template
      }),
    startTime: Yup.string().required('Start time is required').test('valid-time', 'Must be a valid time', validateTime),
    endTime: Yup.string()
      .required('End time is required')
      .test('valid-time', 'Must be a valid time', validateTime)
      .test('is-later-than-start-time', 'End time must be later than start time', function (value) {
        const startDate = mergeAppointmentDateTime(sessionData.scheduledStartTime, this.parent.startTime)
        const endDate = mergeAppointmentDateTime(sessionData.scheduledEndTime, value)
        if (startDate.isValid() && endDate.isValid()) {
          return endDate.toDate() > startDate.toDate()
        }
        return true
      }),
    diagnoses: diagnosesValidation,
    therapyTypeSpecialtyId: Yup.string().required('Therapy type is required'),
    addressId: Yup.string().required('Location is required'),
  })
}

function buildInitialValues(sessionData: SessionData): SessionNoteFormValues {
  return {
    diagnoses: sessionData.diagnoses,
    startTime: sessionData.startTime,
    endTime: sessionData.endTime,
    workingDraft: sessionData.workingDraft,
    therapyTypeSpecialtyId: sessionData.therapyTypeSpecialtyId,
    addressId: sessionData.addressId,
  }
}
