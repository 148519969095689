import { styled } from '@mui/material'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { scrollTo } from 'scroll-js'

import { CapProgress } from '@nuna/coverage'
import { OutlineButton, borderGrey } from '@nuna/tunic'

import { ClientHistory } from './components/ClientHistory'
import { ExportPatientRecordButton } from './components/ExportPatientRecordButton'
import { UpcomingSessions } from './components/UpcomingSessions'

export function ClientSessions() {
  const { id } = useParams<{ id: string }>()
  const isAddingNoteState = useState(false)
  const [, setIsAddingNote] = isAddingNoteState

  const handleAddNoteClick = () => {
    setIsAddingNote(true)

    const formEl = document.querySelector('#session-history-item-form')
    const contentEl = document.querySelector('#patient-detail-content')
    if (formEl && contentEl) {
      scrollTo(contentEl, { top: (formEl as HTMLElement).offsetTop - 150 })
    }
  }

  return (
    <main>
      <Header className="v-align">
        <CapProgress patientId={id} />
      </Header>

      <Section>
        <h2 className="h5 mb-4">Upcoming</h2>
        <UpcomingSessions />
      </Section>

      <Section>
        <div className="space-between mb-2" style={{ alignItems: 'center' }}>
          <h2 className="h5 m-0">History</h2>

          <div className="v-align">
            <ExportPatientRecordButton patientId={id} />
            <OutlineButton variant="secondary" onClick={handleAddNoteClick} className="ml-2">
              Add Note
            </OutlineButton>
          </div>
        </div>
        <ClientHistory isAddingNoteState={isAddingNoteState} />
      </Section>
    </main>
  )
}

const Section = styled('section')`
  border-top: 1px solid ${borderGrey};
  padding-top: var(--margin-5);

  & + & {
    margin-top: var(--margin-5);
  }
`

const Header = styled('header')`
  padding-bottom: var(--margin-3);
  padding-top: var(--margin-1);
`
